//
// general.scss
//

body {
    font-family: $font-family-base;
    font-size: 15px;
    color: $dark;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-secondary;
    color: $dark;
}

.hadithsec{padding-top:0;}
.hadith{text-align: center;background-color: #000;color:#f03;}

.btn, .btn:focus, button, button:focus {
    outline: none !important;
}
.proc{background:#eee;}
.hifztable {width:100%;margin:0;padding:0;background:#eee;}

a {
    text-decoration: none !important;
    outline: none;
}

.text-small-times {
	font-size:13px
}

.text-left{text-align:left;}

p {
    line-height: 1.8;
}

.white{color:#fff;}

.hide {display:none}

.strike { text-decoration: line-through; }