//
// cta.scss
//
#aside2 {
    background: #f6ebc1 !important;
    color: #000 !important;
    h5{color:#000}
    color: #fff;
    margin-top: 20px;
    clear: right;
    border-top: 20px solid #fce27c !important;}

#aside1 {
	h5{color: #fff;}
    background: #000 !important;color: #fff;
    border-top: 20px solid #acacac !important;
}

.bg-cta{
    // background-image: url(../images/bg-3.jpg);
    // background-attachment: fixed;
    // background-size: cover;
    // position: relative;
    // background-position: center;
    background:#333;  
}

